/* global jQuery */

window.debug = window.location.hostname === 'localhost';
jQuery.support.cors = true;

window.api_token = localStorage.api_token || "";

window.api = {
  users: {
    url: "https://api.bubbleplan.net/v1/users",
  },
  contactmail: {
    url: "https://api.bubbleplan.net/v1/contactmail",
  },
  login: {
    url: "https://api.bubbleplan.net/v1/users/login",
  },
  forgotpassword: {
    url: "https://api.bubbleplan.net/v1/password/remind",
  },
  reset: {
    url: "https://api.bubbleplan.net/v1/password/reset",
  },
  getuser: {
    url: "https://api.bubbleplan.net/v1/users/me",
  },
  companies: {
    url: "https://api.bubbleplan.net/v1/companies",
  },
  companyinvites: {
    url: "https://api.bubbleplan.net/v1/companyinvites",
  },
  files: {
    url: "https://api.bubbleplan.net/v1/files",
  },
  buy: {
    url: "https://api.bubbleplan.net/v1/buy",
  },
};
window.app = { url: "https://app.bubbleplan.net/#/" };
window.keys = { stripe: "pk_live_WzXkaTeYtwqEh1PUC357fQus" };

if (window.debug) {
  window.api = {
    users: {
      url: "http://localhost:8080/api/v1/users",
    },
    contactmail: {
      url: "http://localhost:8080/api/v1/contactmail",
    },
    login: {
      url: "http://localhost:8080/api/v1/users/login",
    },
    forgotpassword: {
      url: "http://localhost:8080/api/v1/password/remind",
    },
    reset: {
      url: "http://localhost:8080/api/v1/password/reset",
    },
    getuser: {
      url: "http://localhost:8080/api/v1/users/me",
    },
    companies: {
      url: "http://localhost:8080/api/v1/companies",
    },
    companyinvites: {
      url: "http://localhost:8080/api/v1/companyinvites",
    },
    files: {
      url: "http://localhost:8080/api/v1/files",
    },
    buy: {
      url: "http://localhost:8080/api/v1/buy",
    },
  };
  window.app = { url: "http://localhost:8081/#/" };
  window.keys = { stripe: "pk_test_ZSvv85IquUi9RIVLObsc4lmw" };
}

window.isValidEmailAddress = function (emailAddress) {
  const pattern = new RegExp(/^(("[\w-+\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-+\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-+]{0,66})\.([a-z]{2,10}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][\d]\.|1[\d]{2}\.|[\d]{1,2}\.))((25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\.){2}(25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\]?$)/i);
  return pattern.test(emailAddress);
};

window.objToString = function (obj) {
  let str = '';
  for (const p in obj) {
    if (obj.hasOwnProperty(p)) {
      str += `${p}::${obj[p]}\n`;
    }
  }
  return str;
};

window.formatApiAnswer = function (message) {
  try {
    message = window.objToString(JSON.parse(message));
  } catch (e) {
    //
  }
  return message;
};

window.logout = function (redirect) {
  $.ajax({
    type: "GET",
    url: `${window.api.users.url }/logout`,
    dataType: "json",
    headers: { 'X-Auth-Token': window.api_token },
  })
  .always(() => {
    localStorage.removeItem(window.api_token);
    window.api_token = "";
    sessionStorage.clear();
    $("body").removeClass("authentified");
    if (redirect) window.location.href = redirect;
  });
};

function getMe() {
  $.ajax({
    type: "GET",
    url: window.api.getuser.url,
    dataType: "json",
    headers: { 'X-Auth-Token': window.api_token },
  })
  .then((data) => {
    const { user } = data;
    if (user && user.id) {
      sessionStorage.user_id = user.id;
      sessionStorage.username = user.firstname + ' ' + user.lastname;
      sessionStorage.user_email = user.email;
      $("body").addClass("authentified");
    }
  })
  .catch(() => {
    localStorage.api_token = "";
    window.api_token = "";
    $("body").removeClass("authentified");
  });
}

if (sessionStorage.user_id) $("body").addClass("authentified");
if (localStorage.api_token && ! sessionStorage.user_id) getMe();

/** ***************** */
/* SOURCE (ADWORDS) */
/** ***************** */
// {lpurl}?src=adw&device={device}&keyword={keyword}&matchtype={matchtype}
if (window.location.search.length > 1) {
  const urlParams = { date: (new Date()).toJSON() };
  decodeURIComponent(window.location.search).replace(/[?&]+([^=&]+)=([^&]*)/gi, (s, k, v) => { urlParams[k] = v; });
  if (urlParams.src || urlParams.utm_source) {
    if (! localStorage.source) localStorage.source = JSON.stringify(urlParams);
    if (window.history != undefined && window.history.pushState != undefined) {
      // remove search params (keep gclid for adwords / analytics linking)
      let url = window.location.pathname;
      if (urlParams.gclid) url += `?gclid=${urlParams.gclid}`;
      window.history.pushState({}, document.title, url);
    }
  }
}
$("#userSrc").val(localStorage.source || "");

/** ******************* */
/** * CONTACT FORM *** */
/** ******************* */
$(() => {
  $(".contactform").submit(function (event) {
    event.preventDefault();
    window.submitContactForm(this);
  });
});

window.submitContactForm = function (form) {
  const thisform = $(form);
  const jstexts = window.jstexts || {};

  const name = thisform.find("[name='name']").val();
  const email = thisform.find("[name='email']").val();
  const subject = thisform.find("[name='subject']").val();
  const message = thisform.find("[name='message']").val();
  const wouldLikes = thisform.find("[name='would-like']").get().filter(input => input.checked);
  let messagePrefix = '';
  if (wouldLikes.length) {
    messagePrefix = `${jstexts.WouldLike}\n`;
    messagePrefix += wouldLikes.map(input => `- ${$(input).closest("label").text()}`).join('\n');
    messagePrefix += '\n\n';
  }

  const contactMsg = thisform.find("#contactMsg");
  if (name === "" || email === "" || (subject === "" && message === "")) {
    contactMsg.text(jstexts.MissingField || "Please fill in the fields above.").show();
    return;
  }
  if (! window.isValidEmailAddress(email)) {
    contactMsg.text(jstexts.InvalidEmail || "Please enter a valid e-mail so we can answer back.").show();
    return;
  }

  // Ok data validated
  const formData = new FormData(thisform[0]);
  const data = {};
  formData.forEach((value, key) => {
    data[key] = (key === 'message' ? messagePrefix : '') + value;
  });
  thisform.css('cursor', 'wait').find("input, textarea, button").prop("disabled", true).css('cursor', 'wait');

  $.ajax({
    type: "POST",
    url: window.api.contactmail.url,
    data,
    headers: { 'X-Auth-Token': window.api_token },
  })
  .then(() => {
    thisform.find("input[type='checkbox']").prop("checked", false);
    thisform.find("input, textarea").val("");
    contactMsg.text(jstexts.MessageSent || "Your message has been sent.").show();
    thisform.css('cursor', '').find("input, textarea, button").prop("disabled", false).css('cursor', '');
    const cb = thisform.data("callback");
    if (cb) eval(cb);
  })
  .catch((data) => {
    let response = {};
    try {
      response = data.responseText ? JSON.parse(data.responseText) : {};
    } catch (e) {
      //
    }
    response.message = response.message || data.statusText;
    contactMsg.text(`${jstexts.Error || "Error"} : ${response.message}`).show();
    thisform.css('cursor', '').find("input, textarea, button").prop("disabled", false).css('cursor', '');
  });
};
