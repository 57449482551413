const { jstexts, api } = window;

$(() => {
  /** ******************* */
  /** * REGISTER PAGE *** */
  /** ******************* */

  /* REGISTER */

  $(".registerform").submit(function (event) {
    event.preventDefault();

    const thisform = $(this);
    const firstname = thisform.find("[name=firstname]").val();
    const lastname = thisform.find("[name=lastname]").val();
    const email = thisform.find("[name=email]").val();
    const phone = thisform.find("[name=phone]").val();
    const lang = thisform.find("[name=lang]").val();
    const source = thisform.find("[name=source]").val();
    const password = thisform.find("[name=password]").val();
    const billingPlan = thisform.find("[name=package]").val();

    const errorMsg = thisform.find(".error").empty().hide();
    const noticeMsg = thisform.find(".notice").empty().hide();

    if (firstname === "" || lastname === "" || email === "" || password === "" || phone === "") {
      errorMsg.text(jstexts.MissingField).show();
      return;
    }
    if (! window.isValidEmailAddress(email)) {
      errorMsg.text(jstexts.InvalidEmail).show();
      return;
    }
    if (password.length < 8) {
      errorMsg.text(jstexts.PasswordShort).show();
      return;
    }
    if (password.length > 30) {
      errorMsg.text(jstexts.PasswordLong).show();
      return;
    }

    // Ok data validated
    thisform.css('cursor', 'wait').find("input, button").prop("disabled", true).css('cursor', 'wait');

    $.ajax({
      type: "POST",
      url: api.users.url,
      data: {
        firstname, lastname, email, phone, lang, source, password, billing_plan: billingPlan,
      },
      dataType: "json",
    })
      .then((data) => {
      // console.log("success",data);
      localStorage.api_token = data.user.remember_token;
      noticeMsg.text(jstexts.AccountCreated).show();

      // Registration tracking
      try {
        if (! window.debug) {
          if (window.dataLayer) { // Tag Manager
            const mailpro = /@(yahoo|gmail|hotmail|outlook|mail|sfr|orange)\./i.test(email) ? 0 : 1;
            window.dataLayer.push({ event: 'account-create', mailpro });
          }
          window.pagesense = window.pagesense || []; // Zoho PageSense
          window.pagesense.push(['trackEvent', 'account-create']);

          setTimeout(() => {
            window.location.href = `${window.app.url}auth_token/${data.user.remember_token}`;
          }, 600); // leave time for tracking
          return;
        }
      } catch (err) {
        console.error(err);
      }

      window.location.href = `${window.app.url}auth_token/${data.user.remember_token}`;
    })
      .catch((data) => {
      // console.log("fail");
      // console.log(data);
      const response = data.responseText ? JSON.parse(data.responseText) : {};
      response.message = response.message || { Oops: `${jstexts.SomethingWrong} : ${data.statusText}` };
      // console.log(response.message);
      if (response.message.email && response.message.email.length && response.message.email[0] == "The email has already been taken.") {
        errorMsg.text(jstexts.Emailtaken).show();
      } else {
        errorMsg.text(`${jstexts.Error} : ${window.objToString(response.message)}`).show();
      }
      thisform.css('cursor', '').find("input, button").prop("disabled", false).css('cursor', '');
    });
  });

  /* LOGIN */

  $(".loginform").submit(function (event) {
    event.preventDefault();
    const thisform = $(this);

    const email = thisform.find("[name=email]").val();
    const password = thisform.find("[name=password]").val();
    const captcha = thisform.find("[name=g-recaptcha-response]").val();

    const errorEnterEmailPasswordMsg = thisform.find(".error-mailpassword").hide();
    const errorOtherMsg = thisform.find(".error-other").hide();
    const noticeLoginSuccessMsg = thisform.find(".notice-login-success").hide();

    if (email === "" || password === "") {
      errorEnterEmailPasswordMsg.show(300);
      return;
    }

    // Ok data validated
    thisform.css('cursor', 'wait').find("input, button").prop("disabled", true).css('cursor', 'wait');
    thisform.find(".captcha").empty();

    const success = function (data) {
      // console.log("success",data);
      localStorage.api_token = data.token;
      noticeLoginSuccessMsg.show(300);
      setTimeout(() => {
        window.location.href = `${window.app.url}auth_token/${data.token}`;
      }, 2000);
    };

    const error = function (data) {
      // console.log("fail");
      // console.log(data);
      const response = data.responseText ? JSON.parse(data.responseText) : {};
      response.message = response.message || { Oops: `${jstexts.SomethingWrong} : ${data.statusText}` };
      // console.log(response.message);
      if (typeof response.message == 'string') {
        errorOtherMsg.text(response.message).show(300);
      } else {
        errorOtherMsg.text(`${jstexts.Error} : ${window.objToString(response.message)}`).show();
      }
      thisform.css('cursor', '').find("input, button").prop("disabled", false).css('cursor', '');
      if (data.status == 423) {
        $.ajax({
          type: "GET",
          url: `${window.api.login.url }/captcha`,
          dataType: "html",
          success(captchaResponse) {
            thisform.find(".captcha").html(captchaResponse);
          },
          error: console.log,
        });
      }
    };

    $.ajax({
      type: "POST",
      url: `${window.api.login.url}?user=${email}`,
      data: { email, password, 'g-recaptcha-response': captcha },
      dataType: "json",
      success,
      error,
    });
  });

  /* PASS FORGOT */
  $(".passwordforgotform").submit(function (event) {
    event.preventDefault();

    const thisform = $(this);

    const email = thisform.find("[name=email]").val();

    const errorMsg = thisform.find(".error").empty().hide();
    const noticeMsg = thisform.find(".notice").empty().hide();

    if (email === "") {
      errorMsg.text(jstexts.EnterEmail).show();
      return;
    }

    // Ok data validated
    thisform.css('cursor', 'wait').find("input, button").prop("disabled", true).css('cursor', 'wait');

    $.ajax({
      type: "POST",
      url: api.forgotpassword.url,
      data: { email },
      dataType: "json",
    })
      .then((data) => {
      // console.log("success",data);
      noticeMsg.text(data.message).show();
      thisform.find("[name=email]").val('');
      thisform.css('cursor', '').find("input, button").prop("disabled", false).css('cursor', '');
    })
      .catch((data) => {
      // console.log("fail");
      // console.log(data);
      const response = data.responseText ? JSON.parse(data.responseText) : {};
      response.message = response.message || { Oops: `${jstexts.SomethingWrong} : ${data.statusText}` };
      // console.log(response.message);
      if (typeof response.message == 'string') {
        errorMsg.text(`${jstexts.Error } : ${response.message}`).show();
      } else {
        errorMsg.text(`${jstexts.Error } : ${window.objToString(response.message)}`).show();
      }
      thisform.css('cursor', '').find("input, button").prop("disabled", false).css('cursor', '');
    });
  });

  /* RESET PASSWORD */

  $(".resetpasswordform").submit(function (event) {
    event.preventDefault();

    const thisform = $(this);

    const token = thisform.find("[name=token]").val();
    const password = thisform.find("[name=password]").val();
    const passwordConfirmation = thisform.find("[name=password_confirmation]").val();

    const errorMsg = thisform.find(".error").empty().hide();
    const noticeMsg = thisform.find(".notice").empty().hide();

    if (token === "") {
      errorMsg.text(jstexts.MissingToken).show();
      return;
    }
    if (password === "" || passwordConfirmation === "") {
      errorMsg.text(jstexts.EnterPassword).show();
      return;
    }
    if (password.length < 8) {
      errorMsg.text(jstexts.PasswordShort).show();
      return;
    }
    if (password.length > 30) {
      errorMsg.text(jstexts.PasswordLong).show();
      return;
    }
    if (password !== passwordConfirmation) {
      errorMsg.text(jstexts.PasswordConfirmation).show();
      return;
    }

    // Ok data validated
    thisform.css('cursor', 'wait').find("input, button").prop("disabled", true).css('cursor', 'wait');

    $.ajax({
      type: "POST",
      url: api.reset.url,
      data: { token, password, password_confirmation: passwordConfirmation },
      dataType: "json",
    })
      .then((data) => {
      // console.log("success",data);
      noticeMsg.text(data.message).show();
      thisform.find("[name=password]").val('');
      thisform.find("[name=password_confirmation]").val('');
      thisform.css('cursor', '').find("input, button").prop("disabled", false).css('cursor', '');
    })
      .catch((data) => {
      // console.log("fail");
      // console.log(data);
      const response = data.responseText ? JSON.parse(data.responseText) : {};
      response.message = response.message || { Oops: `${jstexts.SomethingWrong} : ${data.statusText}` };
      // console.log(response.message);
      if (typeof response.message == 'string') {
        errorMsg.text(`${jstexts.Error} : ${response.message}`).show();
      } else {
        errorMsg.text(`${jstexts.Error} : ${window.objToString(response.message)}`).show();
      }
      thisform.css('cursor', '').find("input, button").prop("disabled", false).css('cursor', '');
    });
  });
});

/** ***************** */
/* FORM TRANSITIONS */
/** ***************** */

$(() => {
  function formTransition(from, to) {
    if (to == '.registerform') {
      $('header').removeClass('d-none');
      $('.main-content').removeClass('mt-8');
    } else {
      $('header').addClass('d-none');
      $('.main-content').addClass('mt-8');
    }

    $(from).hide(0, () => {
      $(to).fadeIn(400, () => {
        $('[autofocus]:visible').trigger('focus');
      });
    });
  }

  $('.registerform a').on('click', function (event) {
    const action = $(this).attr('action');
    if (action) {
      event.preventDefault();
      formTransition(".registerform", action);
    }
  });
  $('.loginform a').on('click', function (event) {
    const action = $(this).attr('action');
    if (action) {
      event.preventDefault();
      formTransition(".loginform", action);
    }
  });
  $('.passwordforgotform a').on('click', function (event) {
    const action = $(this).attr('action');
    if (action) {
      event.preventDefault();
      formTransition(".passwordforgotform", action);
    }
  });
  $('.resetpasswordform a').on('click', function (event) {
    const action = $(this).attr('action');
    if (action) {
      event.preventDefault();
      formTransition(".resetpasswordform", action);
    }
  });
});
