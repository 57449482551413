/* Use data-inviewport="class1 class2" to add class1 and class2 when element is inside viewport */

if (window.IntersectionObserver) {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      const classes = (entry.target.dataset.inviewport || '').split(' ');
      classes.forEach((myclass) => {
        entry.target.classList.toggle(myclass, entry.isIntersecting);
      });
    });
  });

  // Attach observer to every [data-inviewport] element:
  document.querySelectorAll('[data-inviewport]').forEach((el) => {
    observer.observe(el);
  });

  // /* Load blog url and fill cards with og data */
  // const loadOgDataObserver = new IntersectionObserver((entries) => {
  //   entries.forEach((entry) => {
  //     if (! entry.isIntersecting) return;
  //     loadOgDataObserver.unobserve(entry.target);
  //     const $el = $(entry.target);
  //     $.get($el.data('loadogdata'), (data) => {
  //       $el.attr('href', $(data).find('meta[property="og:url"]').attr('content'));
  //       $el.css('background-image', `url(${$(data).find('meta[property="og:image"]').attr('content')})`);
  //       $el.find('h3').html($(data).find('meta[property="og:title"]').attr('content'));
  //     });
  //   });
  // }, { rootMargin: '100px' });

  // document.querySelectorAll('[data-loadogdata]').forEach((el) => {
  //   loadOgDataObserver.observe(el);
  // });

  // /* Fetch og data to alimentate include_blog_carousel */
  // const links = ['https://bubbleplan.net/blog/top-5-outils-collaboratifs-entreprise/'];
  // Promise.all(links.map(async (link) => {
  //   const result = [];
  //   await $.get(link, (htmlString) => {
  //     const $html = $('<div/>').html(htmlString);
  //     result.push($html.find('meta[property="og:url"]').attr('content'));
  //     result.push($html.find('meta[property="og:title"]').attr('content'));
  //     result.push($html.find('meta[property="og:image"]').attr('content'));
  //   });
  //   return result;
  // })).then(console.log);
}
